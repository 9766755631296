import React from 'react';
import { graphql } from 'gatsby';

import { FormattedMessage } from 'react-intl';
import { PageHelmet } from '../components/PageHelmet';
import LeftTextRightImage from '../components/SectionBlocks/LeftTextRightImage';
import PageHeader from '../components/PageHeader';
import HighlightListWithIcons
  from '../components/SectionBlocks/HighlightListWithIcons';
import RepeatableSectionSelector
  from '../components/SectionBlocks/RepeatableSectionSelector';
import LeftTitleRightDesc from '../components/SectionBlocks/LeftTitleRightDesc';
import TeamList from '../components/About/TeamList';
import LeftTitleRightHeadline
  from '../components/SectionBlocks/LeftTitleRightHeadline';
import theme from '../styles/theme';

// ********************************* UI Template (Shared with CMS) *********************************
export const OurBusinessPageTemplate = ({
  title,
  description,
  intro,
  bgImage,
  highlights,
  ourMission,
  ourVision,
  coreValues,
  sections,
  sectionsOne,
  team,
}) => (
  <div>
    <PageHelmet title={title} description={description} />
    <PageHeader
      title={(
        <FormattedMessage
          id="about:title.1"
          defaultMessage="Our Business"
        />
        )}
      bgImage={bgImage}
    />
    <LeftTitleRightDesc {...intro} />
    <HighlightListWithIcons {...highlights} />
    <LeftTitleRightHeadline {...ourMission} py={[4, 4, 5]} />
    <LeftTitleRightHeadline {...ourVision} background={theme.color.blue} color={theme.color.white} py={[4, 4, 5]} />
    <HighlightListWithIcons {...coreValues} hasBg />
    {sectionsOne && <RepeatableSectionSelector sections={sectionsOne} />}
    {sections && <RepeatableSectionSelector sections={sections} />}
    <LeftTitleRightDesc {...team} />
    {/*<TeamList {...team} />*/}
  </div>
);

// ********************************* Render page *********************************
const OurBusinessPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return <OurBusinessPageTemplate {...frontmatter} />;
};

export default OurBusinessPage;

// ********************************* Data graphql *********************************
export const pageQuery = graphql`
  query OurBusinessPageWithId($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        bgImage {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        intro {
          title
          desc
          img {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        highlights {
          list {
            label
            number
          }
        }
        ourMission {
          title
          desc
        }
        ourVision {
          title
          desc
        }
        coreValues {
          title
          list {
            title
            desc
          }
        }
        team {
          title
          desc
          members {
            name
            position
            desc {
              content
            }
            profileImg {
              alt
              image {
                childImageSharp {
                  fluid(maxWidth: 1024, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
